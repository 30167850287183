import axios from 'axios'

export const inventoryModule = {
  state: {
    zones: [],
    zoneTypes: [],
    sites: [],
    languages: []
  },
  mutations: {
    storeZones: (state, zones) => {
      state.zones = zones
    },
    storeZoneTypes: (state, zoneTypes) => {
      state.zoneTypes = zoneTypes
    },
    storeSites: (state, sites) => {
      state.sites = sites
    },
    storeLanguages: (state, languages) => {
      state.languages = languages
    }
  },
  actions: {
    getZones: (context, params) => {
      return axios
        .get(
          process.env.VUE_APP_API_URL + '/api/zones',
          {
            params: params
          }
        )
        .then((response) => {
          context.commit('storeZones', response.data.zones)
        })
        .catch(error => console.error(error))
    },
    getZoneTypes: (context, params) => {
      return axios
        .get(
          process.env.VUE_APP_API_URL + '/api/zone-types',
          {
            params: params
          }
        )
        .then((response) => {
          context.commit('storeZoneTypes', response.data.zoneTypes)
        })
        .catch(error => console.error(error))
    },
    getSites: (context) => {
      return axios
        .get(process.env.VUE_APP_API_URL + '/api/sites')
        .then((response) => {
          context.commit('storeSites', response.data.sites)
        })
        .catch(error => console.error(error))
    },
    getLanguages: (context) => {
      return axios
        .get(process.env.VUE_APP_API_URL + '/api/zones')
        .then((response) => {
          const allLanguages = response.data.zones.map((zone) => zone.language)
          const languages = Array.from(new Set(allLanguages.map((language) => language.id)))
            .map(languageId => {
              return allLanguages.find(language => language.id === languageId)
            })

          context.commit('storeLanguages', languages)
        })
        .catch(error => console.error(error))
    }
  },
  getters: {
    getLastUpdate: (state) => (jobKey) => {
      return state.zones.reduce(
        (lastUpdate, actualJob) => {
          const actualJobUpdatedAt = new Date(actualJob.updated_at)
          if (!lastUpdate) {
            return actualJobUpdatedAt
          }

          return lastUpdate.getTime() > actualJobUpdatedAt.getTime() ? lastUpdate : actualJobUpdatedAt
        },
        null
      )
    }
  }
}

export default inventoryModule
