import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../components/pages/home/Home.vue')
  },
  {
    path: '/case-studies',
    name: 'caseStudies',
    component: () => import(/* webpackChunkName: "case-studies" */ '../components/pages/case-studies/CaseStudies.vue')
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: () => import(/* webpackChunkName: "inventory" */ '../components/pages/inventory/Inventory.vue')
  },
  {
    path: '/postbacks',
    name: 'postbacks',
    component: () => import(/* webpackChunkName: "postbacks" */ '../components/pages/postbacks/Postbacks.vue')
  },
  {
    path: '/join-us',
    name: 'joinUs',
    component: () => import(/* webpackChunkName: "join-us" */ '../components/pages/join-us/JoinUs.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../components/pages/login/Login.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "terms" */ '../components/pages/terms/Terms.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../components/pages/privacy/Privacy.vue')
  },
  {
    path: '/legal-notice',
    name: 'legal-notice',
    component: () => import(/* webpackChunkName: "legal-notice" */ '../components/pages/legal-notice/LegalNotice.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../components/pages/contact/Contact.vue')
  },
  {
    path: '/inventory-terms',
    name: 'inventoryTerms',
    component: () => import(/* webpackChunkName: "inventory-terms" */ '../components/pages/inventory-terms/InventoryTerms.vue')
  },
  {
    path: '*',
    name: 'notFound',
    component: () => import(/* webpackChunkName: "notFound" */ '../components/pages/not-found/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
